<script setup lang="ts">
import { fixOverlayScroll } from '@/shared/lib/fixOverlayScroll'

defineOptions({
  inheritAttrs: false,
})

defineProps({
  background: {
    type: String,
    default: 'bg-white',
  },
  // Це милиці які потрібно поміняти
  topIndex: {
    type: Boolean,
    default: false,
  },
  footerButtons: {
    type: String as () => 'col' | 'row',
    default: '',
  },
})

const model = defineModel({ type: Boolean, default: false })

watch(
  () => model.value,
  (opened) => {
    fixOverlayScroll(opened)
  },
)
</script>

<template>
  <Transition name="fade-scale">
    <div
      v-if="model"
      :class="{ '!z-[41]': topIndex }"
      class="fixed bottom-0 left-0 right-0 top-0 z-40 flex items-center justify-center overscroll-none bg-black/75"
    >
      <div
        class="max-h-[98svh] inner-modal-wrapper w-344 rounded-16 flex flex-col justify-between p-16"
        :class="background"
        v-bind="$attrs"
        @click.stop
      >
        <div v-if="$slots.header" class="mb-12 shrink-0 grow-0">
          <slot name="header" />
        </div>
        <div
          class="shrink grow overflow-x-hidden overflow-y-scroll"
          :class="{
            'pt-12': $slots.header,
            'pb-12': $slots.footer,
          }"
        >
          <slot />
        </div>
        <div
          v-if="$slots.footer"
          class="shrink-0 grow-0 pt-12"
          :class="{
            'flex gap-16': footerButtons === 'row',
            'flex flex-col gap-8': footerButtons === 'col',
          }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </Transition>
</template>
<style scoped>
.fade-scale-enter-active,
.fade-scale-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fade-scale-enter-active .inner-modal-wrapper {
  transition: all 0.25s ease-in-out;
  transform: scale(1.1);
}
.fade-scale-leave-active .inner-modal-wrapper {
  transition: all 0.2s ease-out;
  transform: scale(0.6);
}

.fade-scale-enter-to .inner-modal-wrapper {
  transform: scale(1);
}

.fade-scale-enter-from .inner-modal-wrapper {
  transform: scale(0.6);
}

.fade-scale-enter-from,
.fade-scale-leave-to {
  opacity: 0;
}
</style>
